import React from "react";
import { Link } from "react-router-dom";
import "./css/home.scss";
// @ts-ignore
import mainImg from "../static/images/main-image.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import {
  benefits1,
  benefits2,
  benefits3,
  benefits4,
  benefits5,
  benefits6,
  benefits7,
  benefits8,
} from "../static/images";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const Home: React.FC = () => {
  const benefits = [
    {
      name: "Сказки",
      text:
        "Нет ничего мудрее и поучительнее, чем сказка. " +
        "В этом разделе собрано множество народных небылиц и " +
        "авторских произведений. \r\n Сортируйте сказки по тематике " +
        "(про животных, бытовые, волшебные) и приступайте к чтению - " +
        "вы лучше знаете, какая история приглянется малышу.",
      url: benefits1,
    },
    {
      name: "Аудиосказки",
      text:
        "Что может быть лучше маминой сказки на ночь? Только аудиосказка, " +
        "где герои оживают со страниц книг. Предлагаем огромный выбор народных " +
        "и авторских произведений. Аудиосказки наполнят ваш дом теплом, приключениями. " +
        "Поиск по каталогу очень удобен, так как сказки отсортированы по тематике. " +
        "Спойте вместе с Крокодилом Геной, наведайте бабушку вместе с Красной Шапочкой, " +
        "или отыщите золотой ключик с Буратино!",
      url: benefits2,
    },
    {
      name: "Детские песни",
      text:
        "Музыкальное воспитание детей может быть очень увлекательным для всей семьи, " +
        "если правильно подобрать песню. В этой категории собраны самые любимые мелодии из мультфильмов, " +
        "старые добрые колыбельные и авторские работы про всё на свете. Начинайте день с песни - пойте, " +
        "когда умываетесь, делаете зарядку, идете на прогулку. Организовывайте детские музыкальные игры и" +
        " настоящие мюзиклы с нами!",
      url: benefits3,
    },
    {
      name: "Стихи",
      text:
        "Счастливое детство невозможно без  стихотворений. Веселые потешки и " +
        "четверостишия на утренниках в саду мы помним спустя года. Разучивайте " +
        "стихотворения со своими детьми - предлагаем огромный выбор произведений к прочтению. " +
        "Развивайте память, интеллект своего малыша. Учите его декламировать стихи - наш " +
        "каталог поможет определиться с репертуаром!",
      url: benefits4,
    },
    {
      name: "Загадки",
      text:
        "Что может лучше расширить кругозор ребенка и развить его сообразительность, " +
        "чем загадки? Наш каталог содержит веселые задачки, которые заставят задуматься" +
        " даже родителей. Сортируйте материал по алфавиту, используйте поисковую строку, " +
        "если ищете что-то конкретное. Проводите время весело и полезно с загадками о животных," +
        " временах года, фруктах, и многом другом. ",
      url: benefits5,
    },
    {
      name: "Интересные факты",
      text:
        "В мире много интересного. В этой категории собраны самые " +
        "необычные сведения про животных, растения, насекомых, страны. " +
        "Устраивайте конкурсы дома для всей семьи - мы позаботились о том, " +
        "чтобы все интересные факты были в открытом доступе. Используйте материал " +
        "для веселого времяпровождения!",
      url: benefits6,
    },
    {
      name: "Развивалки",
      text:
        "У наших детей есть замечательная возможность развиваться с помощью онлайн-игр, " +
        "задачек, головоломок. Предлагаем увлекательную подборку развлекательных игр," +
        " шарад, логических упражнений для детей любого возраста. Сортируйте доступные " +
        "“развивашки” по алфавиту и тематике. Наш интерактивный портал создан для всестороннего развития!",
      url: benefits7,
    },
    {
      name: "Родителям",
      text:
        "Заботливые мамы и папы всегда в поисках полезной информации. Мы знаем, " +
        "что вы хотите дать своим детям самое лучшее. Интерактивный портал " +
        "“Анютины глазки” поможет родителям организовать незабываемый отдых в кругу семьи. " +
        "Вас ждут интересные идеи для домашнего театра, сценарии детских праздников, ребусы," +
        " конкурсы и занимательные игры. Также в этой категории доступно множество " +
        "дельных советов для родителей о воспитании и развитии детей...  ",
      url: benefits8,
    },
  ];

  return (
    <div className="home">
      <div className="row align-items-center my-5">
        <div className="col-lg-5">
          <h1 className="font-weight-light">Добро пожаловать</h1>
          <p>
            Проект “Анютины глазки” приветствует любознательных детей и их
            родителей. Откройте для себя захватывающий мир, где нет места для
            скуки. Интересные сказки, песни и стихи, необычные загадки, забавные
            факты и творческие задания ждут юных пользователей на нашем сайте
          </p>
          <Link className="about-btn" to="/about">
            Подробнее
          </Link>
        </div>
        <div className="col-lg-7">
          <img
            className="img-fluid rounded mb-4 mb-lg-0"
            src={mainImg}
            alt=""
          />
        </div>
      </div>
      <div className="row align-items-center my-5 benefits">
        {benefits.map((item, index) => {
          return (
            <Link to="ss" className="col-lg-3 benefits-item">
              <div
                className="col-lg-12 benefits-item_bg"
                style={{ backgroundImage: `url(${item.url})` }}
              >
                <div className="benefits-item_icon">
                  <FontAwesomeIcon icon={faBook as IconProp} />
                </div>
                <div className="benefits-item_text">
                  <span>{item.name}</span>
                </div>
                <div className="benefits-item_description">{item.text}</div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Home;

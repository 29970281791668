import React from "react";
import "./css/about.scss";

const About: React.FC = () => {
  return (
    <div className="about">
      <div className="container">
        <div className="row align-items-center my-5">
          <div className="col-lg-12">
            <h1 className="text-center">О нас</h1>
            <p>
              “Анютины глазки” — это портал детской радости, новых знаний и
              родительской заботы. Здесь все, что нужно для развития ребенка.
              Больше не нужно думать, чем занять своего крохотного озорника или
              переборчивого подростка. Онлайн-мир открыт для всех желающих —
              выбирайте сказку к вечернему прочтению всей семьей, настраивайтесь
              на волшебство аудиосказок, пойте детские песни и разучивайте
              стихи.
            </p>
            <p>
              Сайт “Анютины глазки” сумеет привить Вашим быстро взрослеющим
              детям стремление к самостоятельности, развить творческое мышление
              и память. Отдельный раздел для родителей создан для удобства мам и
              пап, которые хотят организовать незабываемый семейный досуг.
            </p>
            <p>
              Как сказал В.Гюго: “Дети — это грядущее!”. Вкладывайте в своих
              сыновей и дочерей самое лучшее. “Анютины глазки” — это кладезь
              материалов для детей любого возраста. Творите, обучайтесь,
              познавайте окружающий мир с нашим развлекательным порталом!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
